class Main {

    constructor() {
        this.that = this;
        this.name = "Main";
    }

    init() {
        $(() => {
            document.addEventListener('contextmenu', event => event.preventDefault());
        });
        // this.geo = true;
        // this.parser = document.createElement('a');
        // this.parser.href = window.location.href;
        // if (!sessionStorage.getItem('alertGeo')) {
        //     sessionStorage.setItem('alertGeo', 'false');
        // }
        // navigator.geolocation.getCurrentPosition(() => {
        //     this.geo = true;
        //     this.initGeo();
        // }, () => {
        //     this.geo = false;
        //     if (this.parser.pathname.includes('live')) {
        //         sessionStorage.setItem('alertGeo', 'false');
        //     }
        //     this.initGeo();
        // });

    }
    // initGeo() {
    //     if (!this.geo && sessionStorage.getItem('alertGeo') === 'false') {
    //         window.alert("Pour accéder au service, vous devez activer la géolocalisation sur votre appareil.");
    //         sessionStorage.setItem('alertGeo', 'true');
    //     }
    // }
}

window.main = new Main();
$(document).ready(function () {
    main.init();
    let height = (($('.navbar-light').outerHeight() - $('.navbar-light').children('.nav-wrapper').outerHeight()) / 2) + $('.navbar-light').children('.nav-wrapper').outerHeight()
    $('#menu').css('top', height+'px')
    $( window ).resize(function() {
        let height = (($('.navbar-light').outerHeight() - $('.navbar-light').children('.nav-wrapper').outerHeight()) / 2) + $('.navbar-light').children('.nav-wrapper').outerHeight()
        $('#menu').css('top', height+'px')
    });
});
